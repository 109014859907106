// A 5x7 pixel representation for each capital letter A-Z (plus '.' for demo).
export const LETTERS_SMALL: { [key: string]: string[] } = {
  A: ['  #  ', ' # # ', '#   #', '#####', '#   #', '#   #', '#   #'],
  B: ['#### ', '#   #', '#   #', '#### ', '#   #', '#   #', '#### '],
  C: [' ####', '#    ', '#    ', '#    ', '#    ', '#    ', ' ####'],
  D: ['#### ', '#   #', '#   #', '#   #', '#   #', '#   #', '#### '],
  E: ['#####', '#    ', '#    ', '#####', '#    ', '#    ', '#####'],
  F: ['#####', '#    ', '#    ', '#####', '#    ', '#    ', '#    '],
  G: [' ####', '#    ', '#    ', '#  ##', '#   #', '#   #', ' ####'],
  H: ['#   #', '#   #', '#   #', '#####', '#   #', '#   #', '#   #'],
  I: [' ### ', '  #  ', '  #  ', '  #  ', '  #  ', '  #  ', ' ### '],
  J: ['  ###', '   # ', '   # ', '   # ', '#  # ', '#  # ', ' ##  '],
  K: ['#   #', '#  # ', '# #  ', '##   ', '# #  ', '#  # ', '#   #'],
  L: ['#    ', '#    ', '#    ', '#    ', '#    ', '#    ', '#####'],
  M: ['#   #', '## ##', '# # #', '#   #', '#   #', '#   #', '#   #'],
  N: ['#   #', '##  #', '# # #', '#  ##', '#   #', '#   #', '#   #'],
  O: [' ### ', '#   #', '#   #', '#   #', '#   #', '#   #', ' ### '],
  P: ['#### ', '#   #', '#   #', '#### ', '#    ', '#    ', '#    '],
  Q: [' ### ', '#   #', '#   #', '#   #', '# # #', '#  # ', ' ## #'],
  R: ['#### ', '#   #', '#   #', '#### ', '# #  ', '#  # ', '#   #'],
  S: [' ####', '#    ', '#    ', ' ### ', '    #', '    #', '#### '],
  T: ['#####', '  #  ', '  #  ', '  #  ', '  #  ', '  #  ', '  #  '],
  U: ['#   #', '#   #', '#   #', '#   #', '#   #', '#   #', ' ### '],
  V: ['#   #', '#   #', '#   #', '#   #', '#   #', ' # # ', '  #  '],
  W: ['#   #', '#   #', '#   #', '# # #', '# # #', '## ##', '#   #'],
  X: ['#   #', '#   #', ' # # ', '  #  ', ' # # ', '#   #', '#   #'],
  Y: ['#   #', '#   #', ' # # ', '  #  ', '  #  ', '  #  ', '  #  '],
  Z: ['#####', '    #', '   # ', '  #  ', ' #   ', '#    ', '#####'],
  '.': ['     ', '     ', '     ', '     ', '     ', '     ', '  ## '],
  ' ': ['     ', '     ', '     ', '     ', '     ', '     ', '     '],
}

export const LETTERS_LARGE: { [key: string]: string[] } = {
  A: [
    '   #   ',
    '  # #  ',
    ' #   # ',
    ' #   # ',
    ' ##### ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
  ],
  B: [
    ' ####  ',
    ' #   # ',
    ' #   # ',
    ' ####  ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' ####  ',
  ],
  C: [
    '  #### ',
    ' ##    ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    ' ##    ',
    '  #### ',
  ],
  D: [
    ' ####  ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' ####  ',
  ],
  E: [
    ' ######',
    ' #     ',
    ' #     ',
    ' ##### ',
    ' #     ',
    ' #     ',
    ' #     ',
    ' #     ',
    ' ######',
  ],
  F: [
    ' ######',
    ' #     ',
    ' #     ',
    ' ##### ',
    ' #     ',
    ' #     ',
    ' #     ',
    ' #     ',
    ' #     ',
  ],
  G: [
    '  #### ',
    ' ##    ',
    '##     ',
    '##  ###',
    '##   # ',
    '##   # ',
    '##   # ',
    ' ##  # ',
    '  #### ',
  ],
  H: [
    '#     #',
    '#     #',
    '#     #',
    '#######',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
  ],
  I: [
    '  ###  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '  ###  ',
  ],
  J: [
    '   ### ',
    '    #  ',
    '    #  ',
    '    #  ',
    '    #  ',
    ' #  #  ',
    ' #  #  ',
    ' #  #  ',
    '  ##   ',
  ],
  K: [
    '#     #',
    '#    # ',
    '#   #  ',
    '#  #   ',
    '####   ',
    '#  #   ',
    '#   #  ',
    '#    # ',
    '#     #',
  ],
  L: [
    '#      ',
    '#      ',
    '#      ',
    '#      ',
    '#      ',
    '#      ',
    '#      ',
    '#      ',
    '#######',
  ],
  M: [
    '#     #',
    '##   ##',
    '# # # #',
    '#  #  #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
  ],
  N: [
    '#     #',
    '##    #',
    '# #   #',
    '#  #  #',
    '#   # #',
    '#    ##',
    '#     #',
    '#     #',
    '#     #',
  ],
  O: [
    '  ###  ',
    ' #   # ',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    ' #   # ',
    '  ###  ',
  ],
  P: [
    ' ####  ',
    ' #   # ',
    ' #   # ',
    ' ####  ',
    ' #     ',
    ' #     ',
    ' #     ',
    ' #     ',
    ' #     ',
  ],
  Q: [
    '  ###  ',
    ' #   # ',
    '#     #',
    '#     #',
    '#     #',
    '#   # #',
    '#   # #',
    ' #   # ',
    '  ### #',
  ],
  R: [
    ' ####  ',
    ' #   # ',
    ' #   # ',
    ' ####  ',
    ' #  #  ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
    ' #   # ',
  ],
  S: [
    '  #### ',
    ' #     ',
    ' #     ',
    '  ###  ',
    '     # ',
    '     # ',
    '     # ',
    ' #     ',
    ' ####  ',
  ],
  T: [
    '#######',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
  ],
  U: [
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    '#     #',
    ' #   # ',
    '  ###  ',
  ],
  V: [
    '#     #',
    '#     #',
    '#     #',
    ' #   # ',
    '  # #  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
  ],
  W: [
    '#     #',
    '#     #',
    '#     #',
    '#  #  #',
    '#  #  #',
    '#  #  #',
    '#  #  #',
    ' # # # ',
    '  ###  ',
  ],
  X: [
    '#     #',
    ' #   # ',
    '  # #  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '  # #  ',
    ' #   # ',
    '#     #',
  ],
  Y: [
    '#     #',
    ' #   # ',
    '  # #  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
  ],
  Z: [
    '#######',
    '     # ',
    '    #  ',
    '   #   ',
    '  #    ',
    ' #     ',
    '#      ',
    '#      ',
    '#######',
  ],
  '.': [
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '   ##  ',
  ],
  ' ': [
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
  ],
}

export const LETTERS_BOLD: { [key: string]: string[] } = {
  A: [
    '  ###  ',
    ' ##### ',
    '##   ##',
    '##   ##',
    '#######',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  B: [
    '###### ',
    '##   ##',
    '##   ##',
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '###### ',
  ],
  C: [
    ' ##### ',
    '##   ##',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##   ##',
    ' ##### ',
  ],
  D: [
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '###### ',
  ],
  E: [
    '#######',
    '##     ',
    '##     ',
    '#####  ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '#######',
  ],
  F: [
    '#######',
    '##     ',
    '##     ',
    '#####  ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
  ],
  G: [
    ' ##### ',
    '##   ##',
    '##     ',
    '##     ',
    '##  ###',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  H: [
    '##   ##',
    '##   ##',
    '##   ##',
    '#######',
    '#######',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  I: [
    '#######',
    '   ##  ',
    '   ##  ',
    '   ##  ',
    '   ##  ',
    '   ##  ',
    '   ##  ',
    '   ##  ',
    '#######',
  ],
  J: [
    '  #####',
    '    ## ',
    '    ## ',
    '    ## ',
    '    ## ',
    '##  ## ',
    '##  ## ',
    ' ## ## ',
    '  ###  ',
  ],
  K: [
    '##   ##',
    '##  ## ',
    '## ##  ',
    '####   ',
    '###    ',
    '####   ',
    '## ##  ',
    '##  ## ',
    '##   ##',
  ],
  L: [
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '#######',
  ],
  M: [
    '##   ##',
    '### ###',
    '#######',
    '## # ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  N: [
    '##   ##',
    '###  ##',
    '#### ##',
    '## ####',
    '##  ###',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  O: [
    '  ###  ',
    ' ## ## ',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ## ## ',
    '  ###  ',
  ],
  P: [
    '###### ',
    '##   ##',
    '##   ##',
    '###### ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
  ],
  Q: [
    '  ###  ',
    ' ## ## ',
    '##   ##',
    '##   ##',
    '##   ##',
    '## # ##',
    '##  ## ',
    ' ## ## ',
    '  ### #',
  ],
  R: [
    '###### ',
    '##   ##',
    '##   ##',
    '###### ',
    '###    ',
    '####   ',
    '## ##  ',
    '##  ## ',
    '##   ##',
  ],
  S: [
    ' ##### ',
    '##   ##',
    '##     ',
    ' ##### ',
    '     ##',
    '     ##',
    '     ##',
    '##   ##',
    ' ##### ',
  ],
  T: [
    '#######',
    '  ##   ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
  ],
  U: [
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ######',
  ],
  V: [
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ## ## ',
    ' ## ## ',
    '  ###  ',
    '  ###  ',
    '   #   ',
  ],
  W: [
    '##   ##',
    '##   ##',
    '##   ##',
    '## # ##',
    '## # ##',
    '## # ##',
    '#######',
    '#######',
    '##   ##',
  ],
  X: [
    '##   ##',
    ' ## ## ',
    '  ###  ',
    '   #   ',
    '   #   ',
    '  ###  ',
    ' ## ## ',
    '##   ##',
    '##   ##',
  ],
  Y: [
    '##   ##',
    ' ## ## ',
    '  ###  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
  ],
  Z: [
    '#######',
    '     ##',
    '    ## ',
    '   ##  ',
    '  ##   ',
    ' ##    ',
    '##     ',
    '##     ',
    '#######',
  ],
  '.': [
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '  ##   ',
    '  ##   ',
  ],
}

export const LETTERS_VARIABLE: { [key: string]: string[] } = {
  A: [
    '   ###   ',
    '  #####  ',
    ' ##   ## ',
    '##     ##',
    '##     ##',
    '#########',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  B: [
    '######## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '######## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '######## ',
  ],
  C: [
    '  ###### ',
    ' ##    ##',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    ' ##    ##',
    '  ###### ',
  ],
  D: [
    '######## ',
    '##     ##',
    '##      #',
    '##      #',
    '##      #',
    '##      #',
    '##      #',
    '##      #',
    '##      #',
    '##     ##',
    '######## ',
  ],
  E: [
    '#########',
    '##       ',
    '##       ',
    '#######  ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '#########',
  ],
  F: [
    '#########',
    '##       ',
    '##       ',
    '#######  ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
  ],
  G: [
    '  ###### ',
    ' ##    ##',
    '##       ',
    '##       ',
    '##  #### ',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    ' ##    ##',
    '  ###### ',
  ],
  H: [
    '##     ##',
    '##     ##',
    '##     ##',
    '#########',
    '#########',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  I: [
    '  ###  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '  ###  ',
  ],
  J: [
    '   ####',
    '    #  ',
    '    #  ',
    '    #  ',
    '    #  ',
    '    #  ',
    '    #  ',
    '##  #  ',
    '##  #  ',
    ' ####  ',
    '  ##   ',
  ],
  K: [
    '##    ##',
    '##   ## ',
    '##  ##  ',
    '## ##   ',
    '####    ',
    '## ##   ',
    '##  ##  ',
    '##   ## ',
    '##    ##',
    '##    ##',
    '##    ##',
  ],
  L: [
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '########',
  ],
  M: [
    '##       ##',
    '###     ###',
    '####   ####',
    '## ## ## ##',
    '##  ###  ##',
    '##       ##',
    '##       ##',
    '##       ##',
    '##       ##',
    '##       ##',
    '##       ##',
  ],
  N: [
    '##     ##',
    '###    ##',
    '####   ##',
    '## ##  ##',
    '##  ## ##',
    '##   ####',
    '##    ###',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  O: [
    '  #####  ',
    ' ##   ## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    ' ##   ## ',
    '  #####  ',
    '  #####  ',
  ],
  P: [
    '######## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '######## ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
    '##       ',
  ],
  Q: [
    '  #####  ',
    ' ##   ## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##  #  ##',
    '##   ### ',
    ' ##   ## ',
    '  #####  ',
    '     ##  ',
  ],
  R: [
    '######## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '######## ',
    '##   ##  ',
    '##    ## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  S: [
    '  ###### ',
    ' ##    ##',
    '##       ',
    '##       ',
    '  #####  ',
    '      ## ',
    '       ##',
    '##     ##',
    '##     ##',
    ' ##    ##',
    '  ###### ',
  ],
  T: [
    '#########',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
    '   ##    ',
  ],
  U: [
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    ' ##   ## ',
    '  #####  ',
    '  #####  ',
  ],
  V: [
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    ' ##   ## ',
    ' ##   ## ',
    '  ## ##  ',
    '  ## ##  ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
  ],
  W: [
    '##       ##',
    '##       ##',
    '##       ##',
    '##   #   ##',
    '##  ###  ##',
    '##  ###  ##',
    '##  ###  ##',
    '##  ###  ##',
    '###   ### ',
    '###   ### ',
    ' ##   ##  ',
  ],
  X: [
    '##     ##',
    ' ##   ## ',
    '  ## ##  ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '  ## ##  ',
    ' ##   ## ',
    '##     ##',
    '##     ##',
  ],
  Y: [
    '##     ##',
    ' ##   ## ',
    '  ## ##  ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
    '   ###   ',
  ],
  Z: [
    '#########',
    '      ## ',
    '     ##  ',
    '    ##   ',
    '   ##    ',
    '  ##     ',
    ' ##      ',
    '##       ',
    '##       ',
    '#########',
    '#########',
  ],
  '.': [
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
    '  ##   ',
  ],
  ' ': [
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
  ],
}

export const LETTERS_CLAUDE: { [key: string]: string[] } = {
  A: [
    '   ##   ',
    '  ####  ',
    ' ##  ## ',
    '##    ##',
    '##    ##',
    '########',
    '##    ##',
    '##    ##',
    '##    ##',
  ],
  B: [
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '###### ',
  ],
  C: [
    ' ##### ',
    '##   ##',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##   ##',
    ' ##### ',
  ],
  D: [
    '#####  ',
    '##  ## ',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##  ## ',
    '#####  ',
  ],
  E: [
    '#######',
    '##     ',
    '##     ',
    '##     ',
    '##### ',
    '##     ',
    '##     ',
    '##     ',
    '#######',
  ],
  F: [
    '#######',
    '##     ',
    '##     ',
    '##     ',
    '##### ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
  ],
  G: [
    ' ##### ',
    '##   ##',
    '##     ',
    '##     ',
    '## ####',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  H: [
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '#######',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  I: ['###', ' # ', ' # ', ' # ', ' # ', ' # ', ' # ', ' # ', '###'],
  J: [
    '  ####',
    '    ##',
    '    ##',
    '    ##',
    '    ##',
    '    ##',
    '##  ##',
    '##  ##',
    ' #### ',
  ],
  K: [
    '##   ##',
    '##  ## ',
    '## ##  ',
    '####   ',
    '###    ',
    '## ##  ',
    '##  ## ',
    '##   ##',
    '##   ##',
  ],
  L: [
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
    '#######',
  ],
  M: [
    '##     ##',
    '###   ###',
    '#### ####',
    '## ### ##',
    '##  #  ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  N: [
    '##   ##',
    '###  ##',
    '#### ##',
    '## ####',
    '##  ###',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  O: [
    ' ##### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  P: [
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '###### ',
    '##     ',
    '##     ',
    '##     ',
    '##     ',
  ],
  Q: [
    ' ##### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '## # ##',
    '##  ## ',
    ' ### ##',
  ],
  R: [
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    '###### ',
    '##  ## ',
    '##   ##',
    '##   ##',
    '##   ##',
  ],
  S: [
    ' ##### ',
    '##   ##',
    '##     ',
    '##     ',
    ' ##### ',
    '     ##',
    '     ##',
    '##   ##',
    ' ##### ',
  ],
  T: [
    '#######',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
  ],
  U: [
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  V: [
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ## ## ',
    '  ###  ',
    '   #   ',
  ],
  W: [
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##  #  ##',
    '## ### ##',
    '###   ###',
    '##     ##',
    '##     ##',
  ],
  X: [
    '##   ##',
    '##   ##',
    ' ## ## ',
    '  ###  ',
    '   #   ',
    '  ###  ',
    ' ## ## ',
    '##   ##',
    '##   ##',
  ],
  Y: [
    '##   ##',
    '##   ##',
    '##   ##',
    ' ## ## ',
    '  ###  ',
    '   #   ',
    '   #   ',
    '   #   ',
    '   #   ',
  ],
  Z: [
    '#######',
    '     ##',
    '    ## ',
    '   ##  ',
    '  ##   ',
    ' ##    ',
    '##     ',
    '##     ',
    '#######',
  ],
  '.': ['   ', '   ', '   ', '   ', '   ', '   ', '   ', ' ##', ' ##'],
  ',': ['   ', '   ', '   ', '   ', '   ', '   ', ' ##', ' ##', '## '],
  '!': [' ##', ' ##', ' ##', ' ##', ' ##', ' ##', '   ', ' ##', ' ##'],
  '?': [
    ' ##### ',
    '##   ##',
    '     ##',
    '    ## ',
    '   ##  ',
    '  ##   ',
    '       ',
    '  ##   ',
    '  ##   ',
  ],
  ':': ['   ', ' ##', ' ##', '   ', '   ', '   ', '   ', ' ##', ' ##'],
  ';': ['   ', ' ##', ' ##', '   ', '   ', '   ', ' ##', ' ##', '## '],
  '0': [
    ' ##### ',
    '##   ##',
    '##  ###',
    '## ####',
    '#### ##',
    '###  ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  '1': [
    '  ##  ',
    ' ### ',
    '####  ',
    '  ##  ',
    '  ##  ',
    '  ##  ',
    '  ##  ',
    '  ##  ',
    '######',
  ],
  '2': [
    ' ##### ',
    '##   ##',
    '     ##',
    '    ## ',
    '   ##  ',
    '  ##   ',
    ' ##    ',
    '##     ',
    '#######',
  ],
  '3': [
    ' ##### ',
    '##   ##',
    '     ##',
    '     ##',
    '  #### ',
    '     ##',
    '     ##',
    '##   ##',
    ' ##### ',
  ],
  '4': [
    '    ## ',
    '   ### ',
    '  #### ',
    ' ## ## ',
    '##  ## ',
    '#######',
    '    ## ',
    '    ## ',
    '    ## ',
  ],
  '5': [
    '#######',
    '##     ',
    '##     ',
    '###### ',
    '     ##',
    '     ##',
    '     ##',
    '##   ##',
    ' ##### ',
  ],
  '6': [
    ' ##### ',
    '##   ##',
    '##     ',
    '##     ',
    '###### ',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  '7': [
    '#######',
    '     ##',
    '    ## ',
    '   ##  ',
    '  ##   ',
    ' ##    ',
    ' ##    ',
    ' ##    ',
    ' ##    ',
  ],
  '8': [
    ' ##### ',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ##### ',
  ],
  '9': [
    ' ##### ',
    '##   ##',
    '##   ##',
    '##   ##',
    ' ######',
    '     ##',
    '     ##',
    '##   ##',
    ' ##### ',
  ],
  ' ': ['    ', '    ', '    ', '    ', '    ', '    ', '    ', '    ', '    '],
  '-': [
    '      ',
    '      ',
    '      ',
    '      ',
    '######',
    '      ',
    '      ',
    '      ',
    '      ',
  ],
  '+': [
    '      ',
    '      ',
    '  ##  ',
    '  ##  ',
    '######',
    '  ##  ',
    '  ##  ',
    '      ',
    '      ',
  ],
  '=': [
    '      ',
    '      ',
    '      ',
    '######',
    '      ',
    '######',
    '      ',
    '      ',
    '      ',
  ],
  '/': [
    '     ##',
    '    ## ',
    '   ##  ',
    '  ##   ',
    ' ##    ',
    '##     ',
    '       ',
    '       ',
    '       ',
  ],
  '\\': [
    '##     ',
    ' ##    ',
    '  ##   ',
    '   ##  ',
    '    ## ',
    '     ##',
    '       ',
    '       ',
    '       ',
  ],
  _: [
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '       ',
    '#######',
  ],
}

export const LETTERS_NICE: { [key: string]: string[] } = {
  A: [
    '   ###   ',
    '  ## ##  ',
    ' ##   ## ',
    '##     ##',
    '##     ##',
    '#########',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  B: [
    '######  ',
    '##   ## ',
    '##    ##',
    '##   ## ',
    '######  ',
    '##   ## ',
    '##    ##',
    '##   ## ',
    '######  ',
  ],
  C: [
    '  ##### ',
    ' ##   ##',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    ' ##   ##',
    '  ##### ',
  ],
  D: [
    '######  ',
    '##   ## ',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##   ## ',
    '######  ',
  ],
  E: [
    '######',
    '##    ',
    '##    ',
    '##    ',
    '##### ',
    '##    ',
    '##    ',
    '##    ',
    '######',
  ],
  F: [
    '########',
    '##      ',
    '##      ',
    '##      ',
    '####### ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
  ],
  G: [
    '  ##### ',
    ' ##   ##',
    '##      ',
    '##      ',
    '##  ####',
    '##    ##',
    '##    ##',
    ' ##   ##',
    '  ##### ',
  ],
  H: [
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '########',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
  ],
  I: [' ## ', ' ## ', ' ## ', ' ## ', ' ## ', ' ## ', ' ## ', ' ## ', ' ## '],
  J: [
    '   ## ',
    '   ## ',
    '   ## ',
    '   ## ',
    '   ## ',
    '   ## ',
    '   ## ',
    '## ## ',
    ' ###  ',
  ],
  K: [
    '##    ##',
    '##   ## ',
    '##  ##  ',
    '## ##   ',
    '####    ',
    '## ##   ',
    '##  ##  ',
    '##   ## ',
    '##    ##',
  ],
  L: [
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
    '########',
  ],
  M: [
    '##     ##',
    '###   ###',
    '#### ####',
    '## ### ##',
    '##  #  ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
  ],
  N: [
    '##    ##',
    '##    ##',
    '###   ##',
    '####  ##',
    '## ## ##',
    '##  ####',
    '##   ###',
    '##    ##',
    '##    ##',
  ],
  O: [
    '  #####  ',
    ' ##   ## ',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    '##     ##',
    ' ##   ## ',
    '  #####  ',
  ],
  P: [
    '####### ',
    '##    ##',
    '##    ##',
    '##    ##',
    '####### ',
    '##      ',
    '##      ',
    '##      ',
    '##      ',
  ],
  Q: [
    '  ####  ',
    ' ##  ## ',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##  # ##',
    ' ##  ## ',
    '  #### #',
  ],
  R: [
    '####### ',
    '##    ##',
    '##    ##',
    '##    ##',
    '####### ',
    '##  ##  ',
    '##   ## ',
    '##    ##',
    '##    ##',
  ],
  S: [
    ' ###### ',
    '##    ##',
    '##      ',
    '##      ',
    ' ###### ',
    '      ##',
    '      ##',
    '##    ##',
    ' ###### ',
  ],
  T: [
    '########',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
  ],
  U: [
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    ' ##  ## ',
    '  ####  ',
  ],
  V: [
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    '##    ##',
    ' ##  ## ',
    '  ####  ',
    '   ##   ',
    '   ##   ',
  ],
  W: [
    '##      ##',
    '##      ##',
    '##      ##',
    '##      ##',
    '##  ##  ##',
    '## #### ##',
    '####  ####',
    '###    ###',
    '##      ##',
  ],
  X: [
    '##    ##',
    '##    ##',
    ' ##  ## ',
    '  ####  ',
    '   ##   ',
    '  ####  ',
    ' ##  ## ',
    '##    ##',
    '##    ##',
  ],
  Y: [
    '##    ##',
    '##    ##',
    ' ##  ## ',
    '  ####  ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
    '   ##   ',
  ],
  Z: [
    '########',
    '      ##',
    '     ## ',
    '    ##  ',
    '   ##   ',
    '  ##    ',
    ' ##     ',
    '##      ',
    '########',
  ],
  '.': ['    ', '    ', '    ', '    ', '    ', '    ', '    ', ' ## ', ' ## '],
  ' ': [
    '     ',
    '     ',
    '     ',
    '     ',
    '     ',
    '     ',
    '     ',
    '     ',
    '     ',
  ],
}
